import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Box } from '@material-ui/core'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { WistiaVideoDetailsPromise } from '@hooks/get-wistia-video-details'
import useStringTranslation from '@hooks/use-string-translation'
import { H4 } from '@system'

const formatTime = (time) => {
  const formatted = Math.round(time / 60)
  return `${formatted} MIN`
}

const useStyles = makeStyles((theme) => {
  return {
    thumbnailContent: {},
    nowPlaying: {
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '1.5px',
      marginLeft: '19.5px',
      justifyContent: 'center',
      color: theme.palette.text.plant,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down('md')]: {
        fontSize: '11px',
        lineHeight: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '24px',
      },
    },
    thumbnail: {
      height: '88.5px',
      width: '150px',
    },
    videoTitle: {
      color: (props) =>
        props.isNowPlaying
          ? theme.palette.text.primary
          : theme.palette.text.tertiary,
      fontWeight: (props) =>
        props.isNowPlaying
          ? theme.typography.fontWeightBold
          : theme.typography.fontWeightLight,
      justifyContent: 'center',
      maxWidth: '200px',
      maxHeight: '88.5px',
      marginLeft: '19.5px',
      fontSize: '17px',
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '17px',
        lineHeight: '24px',
      },
    },
    time: {
      height: '20px',
      width: '40px',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      borderRadius: '2px',
      fontSize: '10px',
      paddingTop: '3px',
      color: theme.palette.text.white,
      position: 'relative',
      bottom: '30px',
      left: '7px',
    },
  }
})

const WistiaVideoPlaylistItem = (props) => {
  const { videoId, title } = props.blok
  const { isNowPlaying, cleanup, playlistPosition } = props
  const [details, setDetails] = useState()
  const mountedRef = useRef(true)

  const classes = useStyles({ isNowPlaying })
  const nowWatching = useStringTranslation('NOW WATCHING')

  const fetchWistiaVideoDetails = useCallback(
    (videoId) => {
      return WistiaVideoDetailsPromise(videoId)
        .then((res) => res.json())
        .then((data) => {
          if (!mountedRef.current) return null
          if (!data) return null
          if (data.error) throw data.error
          setDetails(data)
        })
        .catch((err) =>
          console.warn(
            err,
            'Could not fetch data from wistia. Ensure you own this video!'
          )
        )
    },
    [mountedRef]
  )

  useEffect(() => {
    fetchWistiaVideoDetails(videoId)
    return () => {
      mountedRef.current = false
      cleanup()
    }
  }, [videoId, mountedRef, fetchWistiaVideoDetails, cleanup])

  return (
    !!details &&
    typeof details === 'object' && (
      <SbEditable content={props.blok}>
        <Box className={classes.thumbnailContent}>
          <img
            src={details.thumbnail.url}
            alt="item thumbnail"
            className={classes.thumbnail}
          />
          <Box className={classes.time}>{formatTime(details.duration)}</Box>
        </Box>
        <Box className={classes.videoInfoContainer}>
          {isNowPlaying && (
            <Box className={classes.nowPlaying}>{nowWatching}</Box>
          )}
          <H4 className={classes.videoTitle}>{`${playlistPosition || ''}${
            title || details.name
          }`}</H4>
        </Box>
      </SbEditable>
    )
  )
}

export default WistiaVideoPlaylistItem
