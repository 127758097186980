import { useState, useEffect } from 'react'
import 'isomorphic-fetch'

const baseURL = `https://api.wistia.com/v1`
const key = process.env.WISTIA_READONLY_KEY
const idLink = (id) => `/medias/${id}.json`

const WistiaVideoDetailsService = (videoId) => {
  const [videoDetails, setVideoDetails] = useState(videoId)
  const requestUrl = `${baseURL}${idLink(videoId)}`

  const request = new Request(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`,
    },
  })

  useEffect(() => {
    if (videoId) {
      return fetch(request)
        .then((res) => res.json())
        .then((data) => {
          setVideoDetails(data)
        })
        .catch((err) =>
          console.warn(
            err,
            'Could not fetch data from wistia. Ensure you own this video!'
          )
        )
    }
  }, [videoId, requestUrl])

  return videoDetails
}

export default WistiaVideoDetailsService

export const WistiaVideoDetailsPromise = (videoId) => {
  const requestUrl = `${baseURL}${idLink(videoId)}`

  const request = new Request(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`,
    },
  })

  if (videoId) {
    return fetch(request)
  }
}
